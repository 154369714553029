import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "headline-18 lh-22 mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_details_table = _resolveComponent("details-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.hideTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Lookup results"))
      : _createCommentVNode("", true),
    _createVNode(_component_details_table, {
      items: _ctx.resultsItems,
      "label-size": "large"
    }, {
      status: _withCtx(({ value }) => [
        _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(value)), null, 16)
      ]),
      deliverability: _withCtx(({ value }) => [
        _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(value)), null, 16)
      ]),
      "risk-level": _withCtx(({ value }) => [
        _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(value)), null, 16)
      ]),
      _: 1
    }, 8, ["items"])
  ]))
}