
import { defineComponent } from 'vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'

export default defineComponent({
  name: 'EmailLookupDetailsTable',
  components: { TmStatus, DetailsTable },
  props: {
    hideTitle: {
      type: Boolean,
    },
  },
  setup() {
    const resultsItems = [
      { label: 'Email address', value: 'johndoe@gmail.com' },
      {
        label: 'Status',
        rightClass: 'py-0 d-flex align-center',
        value: {
          name: 'valid',
          color: 'green',
          outline: true,
        },
      },
      {
        label: 'Deliverability',
        rightClass: 'py-0 d-flex align-center',
        value: {
          name: 'deliverable',
          color: 'green',
          outline: true,
        },
      },
      { label: 'Disposable address', value: 'No' },
      { label: 'Email role', value: 'n/a' },
      {
        label: 'Risk level',
        rightClass: 'py-0 d-flex align-center',
        value: {
          name: 'low',
          color: 'blue',
          outline: true,
        },
      },
      { label: 'Type', value: 'Free' },
      { label: 'Local part', value: 'johndoe' },
      { label: 'Domain part', value: 'gmail.com' },
      { label: 'Exchange', value: 'alt2.gmail-smtm-in.l.google.com' },
      { label: 'Preference', value: '20' },
      { label: 'Textmagic whitelist', value: 'No' },
      { label: 'Textmagic blacklist', value: 'No' },
      { label: 'Mail exchange (MX)', value: 'Yes' },
      { label: 'IPv4 (A)', value: 'Yes' },
      { label: 'IPv6 (AAAA)', value: 'Yes' },
    ]

    return {
      resultsItems,
    }
  },
})
