
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import EmailLookupDetailsTable from '@/components/pages/services/emailLookup/shared/EmailLookupDetailsTable.vue'
import CarrierLookupSingleBlock from '@/components/pages/services/carrierLookup/shared/CarrierLookupSingleBlock.vue'
import CarrierLookupSingleFormLine
  from '@/components/pages/services/carrierLookup/shared/CarrierLookupSingleFormLine.vue'

export default defineComponent({
  name: 'ServicesEmailSingleLookup',
  components: { CarrierLookupSingleFormLine, CarrierLookupSingleBlock, EmailLookupDetailsTable, TmButton },
  setup() {
    const phone = ref('')
    const country = ref(phoneCountryVariant[0])
    const visibleResults = ref(false)
    const toggleResults = (val: boolean) => {
      visibleResults.value = val

      if (val) phone.value = 'johndoe@gmail.com'
      else phone.value = ''
    }

    return {
      phoneCountryVariant,
      phone,
      country,
      visibleResults,
      toggleResults,
    }
  },
})
